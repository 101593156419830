const getMean = (data: number[]) => {
	return data.reduce((p,c) => p+c, 0) / data.length
}
const getSD = (data: number[], mean?: number) => {
	const avg = mean??getMean(data)
	const sub = data.reduce((p, c) =>  p + Math.pow(c - avg, 2) , 0)
	return Math.sqrt(sub / data.length)
}
const getMA = (dayCount: number, data: number[]) => {
	let result = [];
	for (let i = 0, len = data.length; i < len; i++) {
		if (i < dayCount) {
			result.push(NaN);
			continue;
		}
		let sum = 0;
		for (let j = 0; j < dayCount; j++) {
			sum += data[i - j];
		}
		result.push(sum / dayCount);
	}
	return result;
}
const getSR = (data: number[]) => {
	const avg = getMean(data)
	const sd = getSD(data, avg)
	return (avg / sd * Math.sqrt(255))
}

const BasicMath = {
	getMean, getSD, getMA, getSR
}

class CustomEMA
{
	VAL: number
	m_alpha: number
	m_counter: number
	LIMIT: number
	Ready: boolean
	constructor(period: number)
	{
		this.VAL = NaN;
		this.m_alpha = 0;
		this.m_counter = 0;
		this.LIMIT = 0;
		this.Ready = false;
		this.m_alpha = 2.0 / (period + 1);
		this.LIMIT = 2 * period;
	}
	Add(value: number)
	{
		if (this.m_counter === 0)
			this.VAL = value;
		this.VAL = this.m_alpha * value + (1.0 - this.m_alpha) * ((!isNaN(this.VAL)) ? this.VAL : 0);
		this.m_counter++;
		if (!isNaN(this.VAL) && this.m_counter > this.LIMIT)
			this.Ready = true;
	}
}

class CustomSMA
{
	m_records: number[]
	m_counter: number
	m_period: number

	VAL: number
	Ready: boolean
	constructor(period: number)
	{
		this.VAL = NaN
		this.Ready = false
		this.m_records = []
		this.m_counter = 0
		if (period)
			this.m_period = period
		else
			this.m_period = 0
	}
	GetCounter(){
		return this.m_counter;
	}
	GetMean(records: number[])
	{
		let sum = 0;
		records.forEach((x)=>{
			sum += x;
		})
		return sum / records.length;
	}
	Add(value: number)
	{
		if (this.m_records.length < this.m_period)
		{
			this.m_records.push(value);
			this.VAL = this.GetMean(this.m_records);
		}
		else if (this.m_records.length >= this.m_period)
		{
			this.Ready = true;
			this.VAL = (this.VAL * this.m_period + value - this.m_records[this.m_counter % this.m_period]) / this.m_period;
			this.m_records[this.m_counter % this.m_period] = value;
			this.m_counter++;
		}
	}
}

class CustomVWAP{
	m_prices: number[]
	m_volumes: number[]
	m_period: number

	VAL: number
	Ready: boolean
	constructor(period: number){
		this.VAL = NaN
		this.Ready = false
		this.m_prices = []
		this.m_volumes = []

		if (period) this.m_period = period
		else this.m_period = 0
	}

	GetMean(records: number[])
	{
		return records.reduce((p,c)=>p+c, 0) / records.length
	}

	GetVWAP(){
		let TotalTO = 0
		let TotalVolume = 0

		for (let i = 0; i < this.m_prices.length; i++){
			TotalTO += this.m_prices[i] * this.m_volumes[i]
			TotalVolume += this.m_volumes[i]
		}

		if (TotalVolume === 0) return this.GetMean(this.m_prices)
		return TotalTO / TotalVolume
	}

	Add(price: number, volume: number){
		this.m_prices.push(price)
		this.m_volumes.push(volume)
		this.m_prices = this.m_prices.slice(-1 * this.m_period)
		this.m_volumes = this.m_volumes.slice(-1 * this.m_period)

		this.VAL = this.GetVWAP()
		this.Ready = this.m_prices.length === this.m_period
	}
}

class CustomRSI{
	m_gainSMA: CustomSMA
	m_lossSMA: CustomSMA
	m_prevVal: number

	RSI: number
	Ready: boolean
	constructor(period: number){
		this.m_gainSMA = new CustomSMA(period)
		this.m_lossSMA = new CustomSMA(period)

		this.RSI = NaN
		this.m_prevVal = NaN
		this.Ready = false
	}

	Add(val: number){
		if (isNaN(this.m_prevVal)){
			this.m_prevVal = val;
		}else{
			let diff = Math.abs(val - this.m_prevVal)

			if (val > this.m_prevVal){
				this.m_gainSMA.Add(diff)
				this.m_lossSMA.Add(0)
			}else if (val < this.m_prevVal){
				this.m_gainSMA.Add(0)
				this.m_lossSMA.Add(diff)
			}

			if (this.m_gainSMA.Ready && this.m_lossSMA.Ready){
				this.RSI = 100 - (100 / (1 + (this.m_gainSMA.VAL / this.m_lossSMA.VAL)))
				this.Ready = true
			}

			this.m_prevVal = val
		}
	}
}

class CustomBBands{
	N: number
	K: number
	data: number[]

	Middle: number[]
	high: number[]
	low: number[]
	constructor(data: number[], N: number, K: number){
		this.N = N
		this.K = K
		this.data = data
		this.Middle = getMA(N, data)
		this.calculateHigh()
		this.calculateLow()

		this.high = []
		this.low = []
	}

	calculateHigh(){
		let highSD = []
		for (let i = 0; i < this.data.length; i++){
			let lastN = this.data.slice(i - (this.N * this.K) + 1, i + 1)
			highSD.push(this.Middle[i] + getSD(lastN))
		}
		this.high = highSD
	}

	calculateLow(){
		let lowSD = []
		for (let i = 0; i < this.data.length; i++){
			let lastN = this.data.slice(i - (this.N * this.K) + 1, i + 1)
			lowSD.push(this.Middle[i] - getSD(lastN))
		}
		this.low = lowSD
	}
}

export { BasicMath, CustomEMA, CustomSMA, CustomVWAP, CustomRSI, CustomBBands }