const CookieHandle = {
    /**
     * Set cookie by key
     * @param {string} key - key of the cookie
     * @param {string} value - value of the cookie
     * @param {number} maxAge - alive time of the cookie in milliseconds (default is 1 day)
     */
    set: (key:string, value:string|String, maxAge:number=86400000) => {
        document.cookie = key + "=" + value + "; path=/; samesite=strict; expires=" + new Date(Date.now() + maxAge).toUTCString()
    }
    ,
    /**
     * Delete cookie by key
     * @param {string} key - The key of the cookie
     */
    del: (key: string) => {
        document.cookie = key + "=; path=/; samesite=strict; expires=" + new Date(0).toUTCString()
    }
    ,
    /**
     * Get cookie's value by key
     * @param {string} key - The key of the cookie
     * @returns {string|null} Return value when exist, otherwise return null
     */
    get: (key: string): string | null => {
        if (document.cookie.length === 0) return null
        const cookies = document.cookie.split(";").map((c)=>{
            const parts = c.trim().split("=")
            return {
                key: parts[0],
                value: parts[1]
            }
        })

        const index = cookies.findIndex((v)=>{ return v.key === key })
        return cookies[index].value
    }
}

export default CookieHandle