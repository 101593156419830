import { useMemo, useState } from "react"

import DateTimeProcessor from "../../../function/DateTimeProcessor"
import { parseNumberToString } from "../../../function/NumberProcessor"
import { GetSpread } from "../../../function/StockHandle"

import { SymbolDefinition } from "../../../function/MetaDataHandle"
import { TradInfo } from "../../../type/WSAPI"
import { Side } from "../../../function/Enums"

import Card from "react-bootstrap/Card"
import { FormControl, InputGroup, Table } from "react-bootstrap"

function GetPnLClass(pnl: number){
    if (pnl > 0) return "text-white bg-success"
    if (pnl < 0) return "text-white bg-danger"
    return "bg-warning"
}

function TradInfoRow(props: {warrantcbbcs: {[key:string]:SymbolDefinition}, TradInfo: TradInfo}){
    const { warrantcbbcs, TradInfo } = useMemo(()=>{ return props }, [props])

    return useMemo(()=>{
        const underlying = (warrantcbbcs[TradInfo.symbol_]??{underlying: TradInfo.symbol_}).underlying
        const type = (warrantcbbcs[TradInfo.symbol_]??{type: "CALL"}).type

        return (
            <tr>
                <td>{DateTimeProcessor.DateToString(new Date(TradInfo.time_string_), DateTimeProcessor.DateStringFormat.hms)}</td>
                <td>{TradInfo.login_id_}</td>
                <td>{underlying}</td>
                <td>{TradInfo.symbol_}</td>
                <td style={{textAlign: "center"}} className={"text-white " + (type==="CALL"||type==="BULL"?"bg-success":"bg-danger")}>{type}</td>
                <td style={{textAlign: "center"}} className={"text-white " + (TradInfo.side_===Side.Bid?"bg-success":"bg-danger")}>{TradInfo.side_ === Side.Bid ? "BUY" : "SELL"}</td>
                <td style={{textAlign: "right"}}>{TradInfo.price_}</td>
                <td style={{textAlign: "right"}}>{parseNumberToString(TradInfo.volume_)}</td>
                <td style={{textAlign: "right"}} className={GetPnLClass(TradInfo.pnl_)}>{TradInfo.pnl_}</td>
                <td style={{textAlign: "right"}} className={GetPnLClass(TradInfo.cum_pnl_)}>{Math.round(TradInfo.cum_pnl_ * 1000) / 1000}</td>
                <td style={{textAlign: "right"}}>{TradInfo.trade_count_}</td>
                <td style={{textAlign: "right"}}>{TradInfo.cum_turnover_}</td>
            </tr>
        )
    }, [warrantcbbcs, TradInfo])
}

function TradInfoTable(props: {acctID: string, symbol: string, warrantcbbcs: {[key:string]:SymbolDefinition}, TradInfos: TradInfo[]}){
    const { acctID, symbol, warrantcbbcs, TradInfos } = useMemo(()=>{ return props }, [props])

    return useMemo(()=>{
        return (
            <Table size="sm" className="shadow" bordered striped hover style={{flex: 4, height: "fit-content"}}>
                <thead>
                    <tr>
                        <th>Time</th>
                        <th>User</th>
                        <th>U</th>
                        <th>D</th>
                        <th>Type</th>
                        <th>Side</th>
                        <th>Price</th>
                        <th>Volume</th>
                        <th>PnL</th>
                        <th>Cum.PnL</th>
                        <th>T.Count</th>
                        <th>Cum.TO</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        TradInfos.map((x, i)=>{
                            if (x.login_id_.indexOf(acctID) === -1) return null
                            if (x.symbol_.toString().indexOf(symbol) === -1) return null
                            return <TradInfoRow key={i} warrantcbbcs={warrantcbbcs} TradInfo={x}/>
                        }).filter((x)=> x!==null).slice(-100).reverse()
                    }
                </tbody>
            </Table>
        )
    }, [warrantcbbcs, acctID, symbol, TradInfos])
}

function AcctID2TradStat(props: {Key2CumPnL: {[key:string]: number}, acctID: string, setAcctID: React.Dispatch<React.SetStateAction<string>>}){
    const { acctID, setAcctID, Key2CumPnL } = useMemo(()=>{ return props }, [props])

    return useMemo(()=>{
        const acctids = Object.keys(Key2CumPnL).filter((x)=>{
            return x.indexOf(acctID) !== -1
        }).sort((a,b)=>{
            return Key2CumPnL[b] - Key2CumPnL[a]
        })

        return (
            <div className="shadow" style={{flex: 1, height: "fit-content"}}>
                <div className="border" style={{padding: 8}}>
                    <InputGroup>
                        <InputGroup.Text>AcctID</InputGroup.Text>
                        <FormControl type="text" value={acctID} onChange={(e)=>{
                            setAcctID(e.target.value.trim())
                        }}/>
                    </InputGroup>
                </div>
                <Table size="sm" bordered striped hover style={{marginBottom: 0}}>
                    <thead>
                        <tr>
                            <th>AcctID</th>
                            <th>Cum.PnL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            acctids.map((x)=>{
                                const pnl = Math.round(Key2CumPnL[x] * 100) / 100

                                return (
                                    <tr key={x}>
                                        <td>{x}</td>
                                        <td style={{textAlign: "right"}} className={GetPnLClass(pnl)}>{pnl}</td>
                                    </tr>
                                )
                            }).splice(0, 100)
                        }
                    </tbody>
                </Table>
            </div>
        )
    }, [acctID, setAcctID, Key2CumPnL])
}

function D2TradStat(props: {
    symbol: string
    setSymbol: React.Dispatch<React.SetStateAction<string>>
    warrantcbbcs: {[key:string]:SymbolDefinition}
    Key2CumPnL: {[key:string]: number}
}){
    const { warrantcbbcs, Key2CumPnL, symbol, setSymbol } = useMemo(()=>{ return props }, [props])

    return useMemo(()=>{
        const symbols = Object.keys(Key2CumPnL).filter((x)=>{
            return x.indexOf(symbol) !== -1
        }).sort((a,b)=>{
            return Key2CumPnL[b] - Key2CumPnL[a]
        })

        return (
            <div className="shadow" style={{flex: 1, height: "fit-content"}}>
                <div className="border" style={{padding: 8}}>
                    <InputGroup>
                        <InputGroup.Text>Symbol</InputGroup.Text>
                        <FormControl type="text" value={symbol} onChange={(e)=>{
                            setSymbol(e.target.value.trim())
                        }}/>
                    </InputGroup>
                </div>
                <Table size="sm" bordered striped hover style={{marginBottom: 0}}>
                    <thead>
                        <tr>
                            <th>Symbol</th>
                            <th>Cum.PnL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            symbols.map((x)=>{
                                const sd = (warrantcbbcs[x]??{type:"CALL"})
                                const color = sd.type === "CALL" || sd.type === "BULL" ? "lightgreen" : "lightpink"
                                const pnl = Math.round(Key2CumPnL[x] * 100) / 100

                                return (
                                    <tr key={x}>
                                        <td style={{backgroundColor: color}} >{x}</td>
                                        <td style={{textAlign: "right"}} className={GetPnLClass(pnl)}>{pnl}</td>
                                    </tr>
                                )
                            }).splice(0, 100)
                        }
                    </tbody>
                </Table>
            </div>
        )
    }, [warrantcbbcs, symbol, setSymbol, Key2CumPnL])
}

function TradInfoPanel(props: {warrantcbbcs: {[key:string]:SymbolDefinition}, TradInfos: TradInfo[]}){
    const { warrantcbbcs, TradInfos } = useMemo(()=>{ return props }, [props])

    const [acctID, setAcctID] = useState<string>("")
    const [symbol, setSymbol] = useState<string>("")

    const AcctID2CumPnL = useMemo(()=>{
        const acct2CumPnL: {[key:string]: number} = {}

        TradInfos.forEach((x)=>{
            if (typeof(acct2CumPnL[x.login_id_]) === "undefined")
                acct2CumPnL[x.login_id_] = 0
            
            acct2CumPnL[x.login_id_] = acct2CumPnL[x.login_id_] + x.pnl_
        })

        return acct2CumPnL
    }, [TradInfos])

    const Symbol2CumPnL = useMemo(()=>{
        const symbol2CumPnL: {[key:string]: number} = {}

        TradInfos.forEach((x)=>{
            if (x.login_id_.indexOf("4116") !== -1) return
            if (typeof(symbol2CumPnL[x.symbol_]) === "undefined")
                symbol2CumPnL[x.symbol_] = 0

            if (Math.round(Math.abs(x.pnl_ / x.volume_ / GetSpread(x.price_))) > 10)
                return
            
            symbol2CumPnL[x.symbol_] = symbol2CumPnL[x.symbol_] + x.pnl_
        })

        return symbol2CumPnL
    }, [TradInfos])

    return useMemo(()=>{
        return (
            <Card className="mb-2">
                <Card.Header className='fs-5'>Trade Infos</Card.Header>
                <Card.Body style={{padding: 0}}>
                    <div style={{display: "flex", gap: "2rem", padding: "1rem 1rem 1rem 1rem", height: 600, overflow: "auto"}}>
                        <AcctID2TradStat acctID={acctID} setAcctID={setAcctID} Key2CumPnL={AcctID2CumPnL}/>
                        <D2TradStat symbol={symbol} setSymbol={setSymbol} warrantcbbcs={warrantcbbcs} Key2CumPnL={Symbol2CumPnL}/>
                        <TradInfoTable acctID={acctID} symbol={symbol} warrantcbbcs={warrantcbbcs} TradInfos={TradInfos}/>
                    </div>
                </Card.Body>
            </Card>
        )
    }, [
        warrantcbbcs, TradInfos,
        acctID, setAcctID, AcctID2CumPnL,
        symbol, setSymbol, Symbol2CumPnL
    ])
}

export default TradInfoPanel