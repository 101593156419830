import { useMemo, useContext } from "react";
import { BasicMath } from "../../../function/CustomMath";
import DataContext from "./Context";

import { Card, Spinner } from "react-bootstrap";
import DateTimeProcessor from "../../../function/DateTimeProcessor";

function BasicData(){
	const {loading, username, totalPnL, totalTurnover, cumPnLs, dailyPnLs, MCMidCurve} = useContext(DataContext)

	const MCPred22Day = useMemo(()=>{
		if (MCMidCurve.length === 0) return 0
		return Math.round(MCMidCurve[MCMidCurve.length - 1] * 100) / 100
	}, [MCMidCurve])

	const { TotalPnL, WinRateTrades, ProfitFactor, AvgHoldDuration, SRTrades } = useMemo(()=>{
		if (cumPnLs.length === 0) return {
			TotalPnL: 0, WinRateTrades: 50, ProfitFactor: 0, AvgHoldDuration: "", SRTrades: 0
		}
		const pnlChanges: number[] = []

		let winCount = 0
		let lossCount = 0
		let totalWin = 0
		let totalLoss = 0

		const avgHoldDuration = cumPnLs.reduce((p,c)=>{ return p + (c.coverEpoch - c.startEpoch) / 1000 }, 0) / cumPnLs.length

		cumPnLs.forEach((x, i)=>{
			if (i !== 0) pnlChanges.push(x.pnl - cumPnLs[i - 1].pnl)

			if (x.pnl > 0) {
				winCount += 1
				totalWin += x.pnl
			}
			else if (x.pnl < 0) {
				lossCount += 1
				totalLoss += x.pnl
			}
		})

		return {
			TotalPnL: Math.round(totalPnL * 100) / 100,
			WinRateTrades: Math.round(winCount / (winCount + lossCount) * 10000) / 100,
			ProfitFactor: Math.round((totalWin / Math.abs(totalLoss)) * 100) / 100,
			AvgHoldDuration: avgHoldDuration < 60 ? Math.round(avgHoldDuration) + " seconds" : avgHoldDuration < 3600 ? Math.round(avgHoldDuration / 60) + " minutes" : avgHoldDuration < 86400 ? Math.round(avgHoldDuration / 3600 * 100) / 100 + " hours" : Math.round(avgHoldDuration / 86400 * 100) / 100 + " days",
			SRTrades: Math.round(BasicMath.getSR(cumPnLs.filter((x)=>{ return x.pnl !== 0 }).map((x)=>{ return x.returnRate })) * 1000) / 1000,
		}
	}, [cumPnLs, totalPnL])

	const { WinRateDaily, AvgDailyPnL, AvgDailyPnLFee, MaxLossDaily, SRDaily, VaR95, VaR99 } = useMemo(()=>{
		if (dailyPnLs.length === 0) return {
			WinRateDaily: 50, AvgDailyPnL: 0, AvgDailyPnLFee: 0, MaxLossDaily: 0, SRDaily: 0,
			VaR95: 0, VaR99: 0
		}

		let winCount = 0
		let lossCount = 0
		let maxLossDaily = 0

		dailyPnLs.forEach((x, i)=>{
			if (x.pnl > 0){
				winCount += 1
			}
			else if (x.pnl < 0){
				lossCount += 1
			}

			maxLossDaily = Math.min(maxLossDaily, x.totalLoss)
		})

		console.log(JSON.stringify(dailyPnLs.map((x)=>{ return x.avgReturnRate })))
		const returnRates = dailyPnLs.map((x)=>{ return x.avgReturnRate * Math.sqrt(x.winCount + x.lossCount) })
		const sd = BasicMath.getSD(returnRates)
		const VaR95 = -1 * sd * 1.65
		const VaR99 = -1 * sd * 2.33

		return {
			WinRateDaily: Math.round(winCount / (winCount + lossCount) * 10000) / 100,
			AvgDailyPnL: Math.round(TotalPnL / dailyPnLs.length * 100) / 100,
			AvgDailyPnLFee: Math.round(dailyPnLs.reduce((p,c)=>p+c.pnlfee, 0) / dailyPnLs.length * 100) / 100,
			MaxLossDaily: Math.round(maxLossDaily * 100) / 100,
			SRDaily: Math.round(BasicMath.getSR(dailyPnLs.map((x)=>{return x.avgReturnRate})) * 1000) / 1000,
			VaR95: Math.round(VaR95 * 10000) / 100,
			VaR99: Math.round(VaR99 * 10000) / 100
		}
	}, [dailyPnLs, TotalPnL])

	return useMemo(()=>{
		if (loading){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">Basic Data</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<Spinner style={{fontSize: 16}}/>
						</div>
					</Card.Body>
				</Card>
			)
		}

		if (cumPnLs.length === 0 || dailyPnLs.length === 0){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">Basic Data</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<span>No Data</span>
						</div>
					</Card.Body>
				</Card>
			)
		}

		return (
			<Card bg="secondary" text="light">
				<Card.Header className="fs-5">{username} Basic Data</Card.Header>
				<Card.Body>
					<div style={{display: "flex", gap: 8}}>
						<div style={{flex: 1, display: "flex", flexDirection: "row", flexFlow: "wrap", gap: 8}}>
							<div className="basic-data-row"><span className="basic-data-title">Total P&L:</span><span className={TotalPnL>0?"text-green-200":"text-red-200"}>{TotalPnL.toLocaleString()}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Avg Daily P&L:</span><span className={AvgDailyPnL>0?"text-green-200":"text-red-200"}>{AvgDailyPnL.toLocaleString()}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Total TO:</span><span>{totalTurnover.toLocaleString()}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Avg Daily P&L after Cost:</span><span className={AvgDailyPnLFee>0?"text-green-200":"text-red-200"}>{AvgDailyPnLFee.toLocaleString()}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Sharpe Ratio (Trades):</span><span>{SRTrades}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Maxiumum Loss per Day:</span><span>{Math.abs(MaxLossDaily).toLocaleString()}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Sharpe Ratio (Daily):</span><span>{SRDaily}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Value at Risk (95%):</span><span>{VaR95}%</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Winning Rate (Trades):</span><span className={WinRateTrades>50?"text-green-200":"text-red-200"}>{WinRateTrades}%</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Value at Risk (99%):</span><span>{VaR99}%</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Winning Rate (Daily):</span><span className={WinRateDaily>50?"text-green-200":"text-red-200"}>{WinRateDaily}%</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Avg Holding Duration:</span><span>{AvgHoldDuration}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Profit Factor:</span><span className={ProfitFactor>1?"text-green-200":"text-red-200"}>{ProfitFactor}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">AI Prediction of PnL (22 Days):</span><span className={MCPred22Day>0?"text-green-200":"text-red-200"}>{MCPred22Day}</span></div>
							<div className="basic-data-row"><span className="basic-data-title">Trading Style:</span></div>
						</div>
					</div>
				</Card.Body>
				<Card.Footer>Lastest Update: {DateTimeProcessor.DateToString(new Date(dailyPnLs[dailyPnLs.length - 1].epoch), DateTimeProcessor.DateStringFormat.yMd)}</Card.Footer>
			</Card>
		)
	}, [
		loading, username, totalTurnover, cumPnLs, dailyPnLs,
		TotalPnL, WinRateTrades, ProfitFactor, AvgHoldDuration, SRTrades,
		WinRateDaily, AvgDailyPnL, AvgDailyPnLFee, MaxLossDaily, SRDaily, VaR95, VaR99,
		MCPred22Day
	])
}

export default BasicData