import { useEffect, useMemo } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import CookieHandle from "../function/CookieHandle";

function TopNav(){
    const [username, loginUUID] = useMemo(()=>{ return [CookieHandle.get("username"), CookieHandle.get("loginUUID")] }, [])

    useEffect(()=>{
        if (!username || !loginUUID){
            window.location.assign("/login")
            return
        }
    }, [username, loginUUID])

    return useMemo(()=>{
        return (
            <Navbar className="mb-2" bg="dark" variant="dark" expand="md">
                <Container fluid>
                    <Navbar.Brand>Client Trades</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navCollapse"/>
                    <Navbar.Collapse id="navCollapse">
                        <Navbar.Text className="me-auto">User: {username}</Navbar.Text>
                        <Nav className="d-flex">
                            <Nav.Link onClick={()=>{
                                CookieHandle.del("username")
                                CookieHandle.del("loginUUID")
                                window.location.assign("/")
                            }}>Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }, [username])
}

export default TopNav