import { useContext, useMemo } from "react"
import DataContext from "./Context";

import { Card, Spinner } from "react-bootstrap";
import ReactECharts from 'echarts-for-react';

interface UnderlyingPnLPair{
	name: string
	value: number
}

function PnLByUnderlyingPie(){
    const {loading, cumPnLs, totalPnL} = useContext(DataContext)

    const Data: UnderlyingPnLPair[] = useMemo(()=>{
        const u2PnLs : { [key: string]: number } = {}
        cumPnLs.forEach((x)=>{
            u2PnLs[x.underlying] = (u2PnLs[x.underlying]??0) + x.pnl
        })

		const Data: UnderlyingPnLPair[] = [{name: "Other", value: 0}]

		const u_ranking = Object.keys(u2PnLs).sort((a,b)=>{
			return u2PnLs[b] - u2PnLs[a]
		})
		u_ranking.forEach((x, i)=>{
			const value = Math.round(u2PnLs[x])
			if (value < 0) return

			if (i >= 5 || value / Math.abs(totalPnL) < 0.025)
				Data[0].value = Data[0].value + value
			else
				Data.push({ name: x, value: value })
		})

        return Data.sort((a,b)=>{
			if (a.name === "Other") return -1
			if (b.name === "Other") return 1
			return a.value - b.value
		})
    }, [cumPnLs, totalPnL])

    return useMemo(()=>{
		if (loading){
			return (
				<Card bg="secondary" text="light" style={{height: "100%"}}>
					<Card.Header className="fs-5">Monthly P&L</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<Spinner style={{fontSize: 16}}/>
						</div>
					</Card.Body>
				</Card>
			)
		}

		if (cumPnLs.length === 0){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">P&L by Underlying</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<span>No Data</span>
						</div>
					</Card.Body>
				</Card>
			)
		}
		
		return (
			<Card bg="secondary" text="light" style={{height: "100%"}}>
				<Card.Header className="fs-5">P&L by Underlying</Card.Header>
				<Card.Body>
					<ReactECharts theme="dark" style={{minHeight: 248, height: "100%"}} option={{
						tooltip: {},
						grid: { top: "10%", bottom: "5%", left: "center"},
						series: {
							name: "P&L by Underlying",
							type: "pie",
							data: Data,
							radius: ['50%', '90%'],
							itemStyle: {
								borderRadius: 10,
								borderColor: '#6c757d',
								borderWidth: 2
							},
							label: {
								position: 'inner',
								fontSize: 14
							}
						}
					}}/>
				</Card.Body>
			</Card>
		)
	}, [loading, cumPnLs, Data])
}

export default PnLByUnderlyingPie