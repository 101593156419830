import { useMemo, useContext } from "react"
import DataContext from "./Context"

import { Card, Spinner } from "react-bootstrap"
import ReactECharts from 'echarts-for-react'

function PnLDistribution(){
	const {loading, dailyPnLs} = useContext(DataContext)

	const { Keys, Values } = useMemo(()=>{
		const { max, min }: {max: number, min: number} = dailyPnLs.reduce((p, c)=>{
            p.min = Math.min(p.min, c.pnl)
            p.max = Math.max(p.max, c.pnl)
            return p
        }, {min: Number.MAX_SAFE_INTEGER, max: Number.MIN_SAFE_INTEGER})

        const range = (max - min) - (max - min) % 1
        const spread = range < 10000 ? 100 : range < 20000 ? 200 : range < 50000 ? 500 : 1000

        const keys: number[] = []
        const startPoint = min - (min % spread) - (min<0?spread:0)
        console.log(startPoint, min, min%spread)
        let i = 0
        while (i * spread + startPoint < max){
            keys.push(i * spread + startPoint)
            i++
        }

        const values = keys.map(()=>{ return 0 })
        dailyPnLs.forEach((x)=>{
            const index = Math.floor((x.pnl - startPoint) / spread)
            values[index] = values[index] + 1
        })

		return {
            Keys: keys.map((x)=>{
                return x.toString() + " ~ " + (x + spread).toString()
            }),
            Values: values
        }
	}, [dailyPnLs])

	return useMemo(()=>{
		if (loading){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">PnL Distribution</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<Spinner style={{fontSize: 16}}/>
						</div>
					</Card.Body>
				</Card>
			)
		}

        if (dailyPnLs.length === 0){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">PnL Distribution</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<span>No Data</span>
						</div>
					</Card.Body>
				</Card>
			)
		}
		
		return (
			<Card bg="secondary" text="white">
				<Card.Header className="fs-5">PnL Distribution</Card.Header>
				<Card.Body>
					<ReactECharts theme="dark" style={{height: 248}} option={{
                        grid: {
							top: "5%", left: "60px", right: "30px", bottom: "10%"
						},
						xAxis: {
							type: "category",
							data: Keys
						},
						yAxis: {
							type: "value"
						},
						tooltip: {},
						dataZoom: {
							type: "inside"
						},
                        series:{
                            type: "bar",
                            data: Values
                        }
                    }}/>
				</Card.Body>
			</Card>
		)
	}, [loading, dailyPnLs, Keys, Values])
}

export default PnLDistribution