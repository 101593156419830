import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage, LoginPage, PortalPage, HistoryPage, RealTimePage } from './page';

import { loadDark } from './function/EChartTheme';
loadDark()

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<HomePage/>}/>
				<Route path='/login' element={<LoginPage/>}/>
				<Route path='/portal' element={<PortalPage/>}/>
				{ HistoryPage /* path: /history */ }
				<Route path="/realtime" element={<RealTimePage/>}/>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
