import { useMemo } from "react";

function HomePage(){
    return useMemo(()=>{
        return (
            <div>
                <h1>Welcome to Client Trades Statistics</h1>
            </div>
        )
    }, [])
}

export default HomePage