import { Route } from "react-router-dom";

import SelectGroupPage from "./SelectGroupPage";
import SelectServerPage from "./SelectServerPage";
import SelectUserPage from "./SelectUserPage";
import UserPage from "./UserPage";


export default (
    <Route path='/history'>
        <Route path='' element={<SelectGroupPage/>}/>
        <Route path=':groupid'>
            <Route path="" element={<SelectServerPage/>}/>
            <Route path=':serverid'>
                <Route path="" element={<SelectUserPage/>}/>
                <Route path=":usernameURI" element={<UserPage/>}/>
            </Route>
        </Route>
    </Route>
)