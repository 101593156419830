import { useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import CookieHandle from "../../function/CookieHandle"
import { WSHandle } from "../../function/WebSocketHandle"
import { GroupData, ServerData, WSResponse } from "../../type/WSAPI"

import { Button, Card, Container, ListGroup, Pagination, Spinner } from "react-bootstrap"
import TopNav from "../../component/TopNav"
import { Permission } from "../../function/Enums"

function SelectUserPage(){
    const { groupid, serverid } = useParams()
    const { groupID, serverID } = useMemo(()=>{
        if (groupid && serverid) return { groupID: parseInt(groupid), serverID: parseInt(serverid)}
        return {groupID: 0, serverID: 0}
    }, [groupid, serverid])

    const [username, loginUUID] = useMemo(()=>{ return [CookieHandle.get("username"), CookieHandle.get("loginUUID")] }, [])

    useEffect(()=>{
        if (!username || !loginUUID){
            window.location.assign("/login")
            return
        }
    }, [username, loginUUID])

    const [ws, setWS] = useState<WSHandle|null>(null)
    const connWS = useRef(false)

    const [loading, setLoading] = useState(true)
    const [group, setGroup] = useState<GroupData|null>(null)
    const [server, setServer] = useState<ServerData|null>(null)
    const [users, setUsers] = useState<string[]>([])
    const [page, setPage] = useState(1)

    useEffect(()=>{
        if (!groupid || !serverid) return;

        if (connWS.current === false && ws === null){
            connWS.current = true

            const websocket = new WSHandle()
            websocket.connect(setWS, (msg)=>{
                const data : WSResponse = JSON.parse(msg)
                console.log(data)
                switch (data.e) {
                    case "loginStatus":
                        if (!data.isLogon){
                            window.location.assign("/login")
                            break
                        }

                        if ((data.permission as number < Permission.Admin) || (typeof(data.group) !== "undefined" && data.group !== groupID)){
                            let targetURL = "/history/"
                            if (data.group) targetURL += data.group.toString() + "/"
                            if (data.server) targetURL += data.server.toString() + "/"
                            if (data.permission as number < Permission.Admin) targetURL += data.username as string
                            window.location.assign(targetURL)
                            break
                        }

                        websocket.send(JSON.stringify({e: "groups"}))
                        websocket.send(JSON.stringify({e: "servers"}))
                        websocket.send(JSON.stringify({e: "users", server: serverID}))
                        break
                    case "groups":
                        if (!data.groups) break

                        const gindex = data.groups.findIndex((g)=>{ return g.id === groupID })
                        if (gindex === -1) break
                        setTimeout(()=>{
                            setGroup((data.groups as GroupData[])[gindex])
                        }, 100)
                        break
                    case "servers":
                        if (!data.servers) break

                        const sindex = data.servers.findIndex((s)=>{ return s.id === serverID })
                        if (sindex === -1){
                            window.location.assign("/history/" + groupid)
                            break
                        }
                        setTimeout(()=>{
                            setServer((data.servers as ServerData[])[sindex])
                        }, 100)
                        break
                    case "users":
                        if (!data.users) break
                        setTimeout(()=>{
                            setUsers(data.users as string[])
                            setLoading(false)
                        }, 150)
                        break
                    default:
                        console.log("Unsupported Message >> " + msg);
                }
            })
        }
    }, [ws, groupid, serverid, groupID, serverID])

    const pagination = useMemo(()=>{
        const pages = Math.ceil(users.length / 10)

        return (
            <Pagination style={{margin: "0 auto", width: "fit-content"}}>
                <Pagination.First disabled={page === 1} onClick={()=>{ setPage(1) }}/>
                <Pagination.Prev disabled={page === 1} onClick={()=>{ setPage(p=>p-1) }}/>

                {page === pages && pages > 2 && <Pagination.Item onClick={()=>{ setPage(p=>p-2) }}>{page - 2}</Pagination.Item>}
                {page > 1 && <Pagination.Item onClick={()=>{ setPage(p=>p-1) }}>{page - 1}</Pagination.Item>}
                <Pagination.Item active>{page}</Pagination.Item>
                {page < pages && <Pagination.Item onClick={()=>{ setPage(p=>p+1) }}>{page + 1}</Pagination.Item>}
                {page === 1 && pages > 2 && <Pagination.Item onClick={()=>{ setPage(p=>p+2) }}>{page + 2}</Pagination.Item>}

                <Pagination.Next disabled={page === pages} onClick={()=>{ setPage(p=>p+1) }}/>
                <Pagination.Last disabled={page === pages} onClick={()=>{ setPage(pages) }}/>
            </Pagination>
        )
    }, [page, users])

    return useMemo(()=>{
        return (
            <div className="bg-dark" style={{minWidth: "100vw", minHeight: "100vh", display: "flex", flexDirection: "column"}}>
                <TopNav />
                <Container className="mt-2 mb-2" style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <div style={{height: "fit-content"}}>
                    <Card bg="secondary" text="white" style={{flex: 0, width: 500, maxWidth: "90%", margin: "auto"}}>
                        <Card.Body>
                            <Card.Title>{group?.name} {server?.name} Users</Card.Title>
                            <Card.Text className="text-mute">Select a server to see the users</Card.Text>
                            {
                                loading?<Spinner/>:
                                <>
                                    <ListGroup className="mb-2">
                                        {
                                            users.sort().slice(page*10-10, page*10).map((u, i)=>{
                                                const url = "/history/" + groupid + "/" + serverid + "/" + u
                                                return <ListGroup.Item key={i} action variant="dark" href={url}>{u}</ListGroup.Item>
                                            })
                                        }
                                    </ListGroup>
                                    <div style={{width: "100%"}}> {pagination} </div>
                                </>
                            }
                            {!loading && <Button className="mt-4" variant="warning" as="a" href={"/history/" + groupid}>Back to Server List</Button>}
                        </Card.Body>
                    </Card>
                    </div>
                </Container>
            </div>
        )
    }, [loading, groupid, serverid, group, server, users, page, pagination])
}

export default SelectUserPage