import { useMemo, useEffect, useState, useRef } from "react";
import CookieHandle from "../../function/CookieHandle";

import TopNav from "../../component/TopNav";
import { Button, Card, Container} from "react-bootstrap";
import { WSHandle } from "../../function/WebSocketHandle";
import { WSResponse } from "../../type/WSAPI";
import { Permission } from "../../function/Enums";

import "./index.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faForward } from "@fortawesome/free-solid-svg-icons";

function PortalPage(){
    const [username, loginUUID] = useMemo(()=>{ return [CookieHandle.get("username"), CookieHandle.get("loginUUID")] }, [])

    useEffect(()=>{
        if (!username || !loginUUID){
            window.location.assign("/login")
            return
        }
    }, [username, loginUUID])

    const [ws, setWS] = useState<WSHandle|null>(null)
    const connWS = useRef(false)

    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        // Before Start Conn
        if (connWS.current === false && ws === null){
            connWS.current = true

            const websocket = new WSHandle()
            websocket.connect(setWS, (msg)=>{
                const data : WSResponse = JSON.parse(msg)
                switch (data.e) {
                    case "loginStatus":
                        if (!data.isLogon){
                            window.location.assign("/login")
                            break
                        }
                        if (data.permission as number < Permission.SuperAdmin){
                            let targetURL = "/history/"
                            if (data.group) targetURL += data.group.toString() + "/"
                            if (data.server) targetURL += data.server.toString() + "/"
                            if (data.permission as number < Permission.Admin) targetURL += data.username
                            window.location.assign(targetURL)
                            break
                        }

                        setLoading(false)
                        break
                    default:
                        console.log("Unsupported Message >> " + msg);
                }
            })
        }
    }, [ws])

    return useMemo(()=>{
        if (loading) return null
        
        return (
            <div className="bg-dark" style={{minWidth: "100vw", minHeight: "100vh", display: "flex", flexDirection: "column"}}>
                <TopNav />
                <Container className="mt-2 mb-2 portal-menu">
                    <Card className="portal-item" bg="secondary" text="white">
                        <Card.Body>
                            <FontAwesomeIcon icon={faClipboard} style={{width: "100%", height: "8rem", marginBottom: "1.5rem"}}/>
                            <Card.Title>History</Card.Title>
                            <Card.Text>Show History Data and Prediction</Card.Text>
                            <Button as="a" href="/history">Go History</Button>
                        </Card.Body>
                    </Card>
                    <Card className="portal-item" bg="secondary" text="white">
                        <Card.Body>
                            <FontAwesomeIcon icon={faForward} style={{width: "100%", height: "8rem", marginBottom: "1.5rem"}}/>
                            <Card.Title>Realtime</Card.Title>
                            <Card.Text>Show Realtime Status</Card.Text>
                            <Button as="a" href="/realtime">Go Realtime</Button>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        )
    }, [loading])
}

export default PortalPage