import { useEffect, useMemo, useRef, useState } from 'react'

import ReactEChart from 'echarts-for-react'
import { CallPutCount } from '../../../type/WSAPI';
import { Card } from 'react-bootstrap';

function splitData(name: string, data: {[key: string]: CallPutCount}, sort?: string[]){
    let splitedData: {underlying: string[], call: number[], put: number[]} = {underlying: [], call: [], put: []}

    if (sort){
        sort.forEach((x) => {
            splitedData.underlying.push(x)
            if (data[x]){
                if (name === "Volume" || name === "Holding"){
                    splitedData.call.push((data[x].call??0) / 10000)
                    splitedData.put.push((data[x].put??0) * -1 / 10000)
                }else if (name === "Count"){
                    splitedData.call.push(data[x].call)
                    splitedData.put.push(data[x].put * -1)
                }
            }
        });
    }
    else{
        Object.keys(data).forEach((x)=>{
            if (data[x].call + data[x].put > 0){
                splitedData.underlying.push(x)
                splitedData.call.push((data[x].call??0) / 10000)
                splitedData.put.push((data[x].put??0) * -1 / 10000)
            }
        })
    }

    return splitedData
}

function getOption(name: string, data: {[key: string]: CallPutCount}, sort?: string[]){
    const splitedData = splitData(name, data, sort);

    let option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: [
            {
                top: "10%",
                bottom: "10%",
                left: "5%",
                right: "7%"
            }
        ],
        xAxis: [
            {
                type: 'value'
            }
        ],
        yAxis: [
            {
                type: "category",
                data: splitedData.underlying.reverse(),
                position: 'right',
                axisTick: {
                    show: false
                }
            }
        ],
        series: [
            {
                name: "Call",
                stack: 'Total',
                type: "bar",
                data: splitedData.call.reverse(),
                itemStyle: {
                    color: "rgb(255,60,60)"
                },
                label: {
                    show: true,
                    valueAnimation: true,
                    formatter: (data: {data: number})=>{
                        if (data.data > 0)
                            return data.data
                        return ""
                    }
                }
            },
            {
                name: "Put",
                stack: 'Total',
                type: "bar",
                data: splitedData.put.reverse(),
                itemStyle: {
                    color: "rgb(60,204,60)"
                },
                label: {
                    show: true,
                    valueAnimation: true,
                    formatter: (data: {data: number})=>{
                        if (data.data < 0)
                            return data.data
                        return ""
                    }
                }
            }
        ]
    }

    return option
}

function CallPutChart({name, data, sort}: {name: string, data: {[key: string]: CallPutCount}, sort?: string[]}){
    const [option, setOption] = useState<any>({})
    const dataRef = useRef<{[key: string]: CallPutCount}|null>(null)

    useEffect(()=>{
        if (dataRef.current === data)
            return

        dataRef.current = data
        setOption(getOption(name, data, sort))
    }, [data, name, sort])

    return useMemo(()=>{
        return (
            <Card style={{flex: 1}}>
                <Card.Header className="fs-5">Call / Put {name} Status</Card.Header>
                <Card.Body>
                    <ReactEChart style={{height: 380}} option={option} lazyUpdate/>
                </Card.Body>
            </Card>
        )
    }, [name, option])
}

export default CallPutChart