import { Dispatch } from "react"

class WSHandle{
    ws: WebSocket|null
    setWS: Dispatch<any>|null
    onMsg: ((msg: string) => void)|null

    wsUrl: string
    heartbeatTimer: NodeJS.Timer|null

    constructor(host:string = window.location.hostname, path:string ="ws", port:number = 0, isWSS:boolean = true){
        if (host === "localhost"){
            host = "localhost"
            port = 9000
            path = ""

            isWSS = false
        }
        if (localStorage.TWS === "true"){
            path = "tws"
            if (port === 9000){
                path = ""
                port = 9010
            }
        }

        this.ws = null
        this.setWS = null
        this.onMsg = null

        this.wsUrl = (isWSS?"wss://":"ws://") + host + (port?(":"+port):"") + "/" + path
        this.heartbeatTimer = null
    }

    connect(setWS: Dispatch<WSHandle>, onMsg=(msg:string)=>{}){
        this.setWS = setWS
        this.onMsg = onMsg
        this.reconnect()

        this.heartbeatTimer = setInterval(()=>{
            if (this.ws && this.ws.readyState === this.ws.OPEN)
                this.ws.send("");
        }, 5000)
    }

    reconnect(msg=""){
        this.ws = new WebSocket(this.wsUrl)
        this.ws.onopen = () => {
            if (this.setWS)
                this.setWS(this)
            
            console.log("Connected")
            if (msg)
                this.send(msg)
        }

        this.ws.onmessage = (data) => {
            if (data.data !== ""){
                const msgs:string[] = data.data.split("\r\n").filter((x:string)=>{return x !== ""})
                msgs.forEach((x)=>{
                    if (this.onMsg)
                        this.onMsg(x)
                })
            }
        }
    }

    send(msg : string){
        if (this.ws && this.ws.readyState === this.ws.OPEN)
            this.ws.send(msg)
        else
            this.reconnect(msg)
    }
}

export { WSHandle }