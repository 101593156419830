import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import CookieHandle from "../../function/CookieHandle";
import { WSHandle } from "../../function/WebSocketHandle";

import { CumPnL, DailyPnL, WSResponse } from "../../type/WSAPI";
import { Permission } from "../../function/Enums";

import { Container } from "react-bootstrap";
import TopNav from "../../component/TopNav";

import { DataContext, BasicData, MonthlyPnL, PnLCurve, PnLByUnderlyingPie, PnLDistribution, PnLByWeekAndTime } from "./UserPage/"

import "./UserPage/UserPage.css"

function UserPage(){
	const { groupid, serverid, usernameURI } = useParams()
    const { groupID, serverID } = useMemo(()=>{
        if (groupid && serverid) return { groupID: parseInt(groupid), serverID: parseInt(serverid)}
        return {groupID: 0, serverID: 0}
    }, [groupid, serverid])

	const [username, loginUUID] = useMemo(()=>{ return [CookieHandle.get("username"), CookieHandle.get("loginUUID")] }, [])

	useEffect(()=>{
		if (!username || !loginUUID){
			window.location.assign("/login")
			return
		}
	}, [username, loginUUID])

	const [ws, setWS] = useState<WSHandle|null>(null)
    const connWS = useRef(false)

    const [loading, setLoading] = useState(true)
	const [totalTurnover, setTotalTurnover] = useState(0)
	const [cumPnLs, setCumPnLs] = useState<CumPnL[]>([])
	const [dailyPnLs, setDailyPnLs] = useState<DailyPnL[]>([])

	const { totalPnL } = useMemo(()=>{
		if (cumPnLs.length === 0) return {
			totalPnL: 0
		}

		let totalPnL = 0
		cumPnLs.forEach((x)=>{
			totalPnL += x.pnl
		})

		return { totalPnL: totalPnL }
	}, [cumPnLs])

	const {MCBestCurve, MCWorseCurve, MCMidCurve} = useMemo(()=>{
		if (dailyPnLs.length === 0){
			return {
				MCBestCurve: [],
				MCWorseCurve: [],
				MCMidCurve: []
			}
		}
		const allPnLs = dailyPnLs.map((x)=>x.pnl)
		const curveArr: number[][] = []

		const PredDays = 22
		for (let i = 0; i < 100; i++){
			const arr: number[] = []
			curveArr.push(arr)
			for (let j = 0; j < PredDays; j++){
				if (j === 0) arr.push(allPnLs[Math.floor(Math.random() * allPnLs.length)])
				else arr.push(arr[j-1] + allPnLs[Math.floor(Math.random() * allPnLs.length)])
			}
		}

		const {best, worse} = curveArr.reduce((p,c)=>{
			if (p.best[PredDays - 1] < c[PredDays - 1])
				p.best = c
			else if (p.worse[PredDays - 1] > c[PredDays - 1])
				p.worse = c
			return p
		}, {best: curveArr[0], worse: curveArr[0]})

		return {
			MCBestCurve: best,
			MCWorseCurve: worse,
			MCMidCurve: best.map((x,i)=>{ return (x + worse[i]) / 2})
		}
	}, [dailyPnLs])

	useEffect(()=>{
        if (!groupid || !serverid || !usernameURI) return

        if (connWS.current === false && ws === null){
            connWS.current = true

            const websocket = new WSHandle()
            websocket.connect(setWS, (msg)=>{
                const data : WSResponse = JSON.parse(msg)
                console.log(data)
                switch (data.e) {
                    case "loginStatus":
                        if (!data.isLogon){
                            window.location.assign("/login")
                            break
                        }

                        if ((data.permission as number < Permission.Admin && usernameURI !== data.username) || (typeof(data.group) !== "undefined" && data.group !== groupID)){
                            let targetURL = "/history/"
                            if (data.group) targetURL += data.group.toString() + "/"
                            if (data.server) targetURL += data.server.toString() + "/"
                            if (data.permission as number < Permission.Admin) targetURL += data.username as string
                            window.location.assign(targetURL)
                            break
                        }

                        websocket.send(JSON.stringify({e: "userdata", username: usernameURI}))
                        break
                    case "userdata":
                        if (typeof(data.totalTurnover) === "undefined" || !data.cumPnLs || !data.dailyPnLs) break
						setTotalTurnover(data.totalTurnover)
						setCumPnLs(data.cumPnLs)
						setDailyPnLs(data.dailyPnLs)

                        setTimeout(()=>{
                            setLoading(false)
                        }, 500)
                        break
                    default:
                        console.log("Unsupported Message >> " + msg);
                }
            })
        }
    }, [ws, groupid, serverid, usernameURI, groupID, serverID])

	return useMemo(()=>{
		return (
			<div className="bg-dark" style={{minWidth: "100vw", minHeight: "100vh", display: "flex", flexDirection: "column"}}>
				<TopNav />
				<Container className="mt-2 mb-2" fluid style={{flex: 1, display: "flex", flexDirection: "column", gap: "1.5rem"}}>
					<DataContext.Provider value={{
						loading: loading,
						username: usernameURI??"",
						totalPnL: totalPnL,
						totalTurnover: totalTurnover,
						cumPnLs: cumPnLs,
						dailyPnLs: dailyPnLs,

						MCBestCurve: MCBestCurve,
						MCWorseCurve: MCWorseCurve,
						MCMidCurve: MCMidCurve
					}}>
						<div style={{display: "flex", flexFlow: "wrap", gap: "1.5rem"}}>
							<div className="basic-data"><BasicData/></div>
							<div className="monthly-pnl"><MonthlyPnL/></div>
						</div>
						<div style={{display: "flex", flexFlow: "wrap", gap: "1.5rem"}}>
							<div className="normal-card"><PnLCurve/></div>
							<div className="normal-card"><PnLByUnderlyingPie/></div>
						</div>
						<div style={{display: "flex", flexFlow: "wrap", gap: "1.5rem"}}>
							<div className="normal-card"><PnLDistribution/></div>
							<div className="normal-card"><PnLByWeekAndTime/></div>
						</div>
					</DataContext.Provider>
				</Container>
			</div>
		)
	}, [loading, usernameURI, totalPnL, totalTurnover, cumPnLs, dailyPnLs, MCBestCurve, MCWorseCurve, MCMidCurve])
}

export default UserPage