import { useMemo, useState } from 'react'

import { Card, InputGroup, Table } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'

import { parseNumberToString } from "../../../function/NumberProcessor"
import { CallPutCount, WSResponse } from '../../../type/WSAPI'

const ActionType = {
    add: 0,
    delete: 1,
    modify: 2,
    place: 3,
    orderResponse: 4
}

function ReturnTime(timestamp: number){
    const date = new Date(timestamp)
    let hour = ("0" + date.getHours()).slice(-2)
    let minutes = ("0" + date.getMinutes()).slice(-2)
    let seconds = ("0" + date.getSeconds()).slice(-2)

    return <td>{hour + ":" + minutes + ":" + seconds}</td>
}

function ReturnCallPut(action: WSResponse, isCall: number){
    if (action.actionType === ActionType.add){
        if (isCall === 0)
            return <td className="bg-success text-white">Put</td>
        else
            return <td className="bg-danger text-white">Call</td>
    }/*else if (action.actionType === ActionType.delete){
        const lastAdd = oldAction.filter((x)=>{
            if (x.row_id_ === action.row_id_ && x.side_ === action.side_ && x.actionType === 0)
                return true
            else
                return false
        }).slice(-1)
        if (lastAdd.length > 0){
            if (lastAdd[0].is_call_ === 0)
                return <td className="bg-success text-white">Put</td>
            else
                return <td className="bg-danger text-white">Call</td>
        }
    }*/
    return <td>N/A</td>
}

function BuySellActionTable({name, actions}: {name: string, actions: WSResponse[]}){
    return useMemo(()=>{
        return (
            <Table size="sm" className="shadow" bordered striped hover style={{flex: 1, height: "fit-content"}}>
                <thead>
                    <tr>
                        <td style={{textAlign:"center"}} className={"text-white "+(name==="Buy"?"bg-danger":"bg-success")} colSpan={8}>{name}</td>
                    </tr>
                    <tr>
                        <th>Time</th>
                        <th>User</th>
                        <th>Underlying</th>
                        <th>Symbol</th>
                        <th>Call / Put</th>
                        <th>TBU/TSU</th>
                        <th>MaxB/MinS</th>
                        <th>Volume</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        actions.map((x, i)=>{
                            return (
                                <tr key={i}>
                                    {ReturnTime(x.timestamp_ as number)}
                                    <td>{x.login_id_}</td>
                                    <td>{x.u_code_}</td>
                                    <td>{x.d_code_}</td>
                                    {ReturnCallPut(x, x.is_call_ as number)}
                                    <td style={{textAlign: "right"}}>{x.actionType===0?Math.max(x.tbu_ as number, x.tsu_ as number)/1000:"-"}</td>
                                    <td style={{textAlign: "right"}}>{x.actionType===0?Math.max(x.max_b_ as number,x.min_s_ as number)/1000:"-"}</td>
                                    <td style={{textAlign: "right"}}>{x.actionType===0?parseNumberToString(x.volume_ as number):"-"}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }, [name, actions])
}

function UserAction(props: {minLights: number, filter: string[], countData: {[key: string]: CallPutCount}, UserActions: WSResponse[]}){
    const [users, setUsers] = useState<string[]>([])

    const filteredActions = useMemo(()=>{
        return props.UserActions.filter((x)=>{
            return x.actionType === ActionType.add && x.u_code_ && (
                props.filter.indexOf("" + x.u_code_) > -1 || (props.minLights === 0 && props.filter.length === 0) ||
                (props.countData[x.u_code_] && props.countData[x.u_code_].call + props.countData[x.u_code_].put >= props.minLights)
            )
        }).reverse()
    }, [props.UserActions, props.filter, props.minLights, props.countData])

    const [AddBuy, AddSell] = useMemo(()=>{
        return [
            filteredActions.filter((x)=>{ return x.side_ === 0 && ( users.length === 0 || (x.login_id_ && users.indexOf(x.login_id_) > -1) ) }).slice(-100),
            filteredActions.filter((x)=>{ return x.side_ === 1 && ( users.length === 0 || (x.login_id_ && users.indexOf(x.login_id_) > -1) ) }).slice(-100)
        ]
    }, [filteredActions, users])

    return useMemo(()=>{
        return (
            <Card className="mb-2">
                <Card.Header className='fs-5'>User Actions</Card.Header>
                <Card.Body>
                    <InputGroup className='mb-2'>
                        <InputGroup.Text>User Filter</InputGroup.Text>
                        <Form.Control type='text' onChange={(e)=>{ setUsers(e.target.value===""?[]:e.target.value.split(" "))}}/>
                    </InputGroup>
                    <div style={{display: "flex", gap: "1rem", paddingBottom: 0}}>
                        <BuySellActionTable name="Buy" actions={AddBuy}/>
                        <BuySellActionTable name="Sell" actions={AddSell}/>
                    </div>
                </Card.Body>
            </Card>
        )
    }, [AddBuy, AddSell])
}

export default UserAction