import { useMemo, useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import CookieHandle from "../../function/CookieHandle";
import { WSHandle } from "../../function/WebSocketHandle";
import { GroupData, ServerData, WSResponse } from "../../type/WSAPI";
import { Permission } from "../../function/Enums";

import { Button, Card, Container, ListGroup, Spinner } from "react-bootstrap";
import TopNav from "../../component/TopNav";

function SelectServerPage(){
    const { groupid } = useParams()
    const { groupID } = useMemo(()=>{
        if (groupid) return { groupID: parseInt(groupid)}
        return {groupID: 0}
    }, [groupid])
    const [username, loginUUID] = useMemo(()=>{ return [CookieHandle.get("username"), CookieHandle.get("loginUUID")] }, [])

    useEffect(()=>{
        if (!username || !loginUUID){
            window.location.assign("/login")
            return
        }
    }, [username, loginUUID])

    const [ws, setWS] = useState<WSHandle|null>(null)
    const connWS = useRef(false)

    const [loading, setLoading] = useState(true)
    const [permission, setPermission] = useState(Permission.Admin)
    const [group, setGroup] = useState<GroupData|null>(null)
    const [servers, setServers] = useState<ServerData[]>([])

    useEffect(()=>{
        if (!groupid) return;

        if (connWS.current === false && ws === null){
            connWS.current = true

            const websocket = new WSHandle()
            websocket.connect(setWS, (msg)=>{
                const data : WSResponse = JSON.parse(msg)
                console.log(data)
                switch (data.e) {
                    case "loginStatus":
                        if (!data.isLogon){
                            window.location.assign("/login")
                            break
                        }

                        if ((data.permission as number < Permission.Admin) || (typeof(data.group) != "undefined" && data.group !== groupID)){
                            let targetURL = "/history/"
                            if (data.group) targetURL += data.group.toString() + "/"
                            if (data.server) targetURL += data.server.toString() + "/"
                            if (data.permission as number < Permission.Admin) targetURL += data.username
                            window.location.assign(targetURL)
                            break
                        }

                        data.permission && setPermission(data.permission)
                        websocket.send(JSON.stringify({e: "groups"}))
                        websocket.send(JSON.stringify({e: "servers"}))
                        break
                    case "groups":
                        if (data.groups){
                            const index = data.groups.findIndex((g)=>{ return g.id === groupID })
                            if (index === -1) return 
                            const g = data.groups[index]
                            setTimeout(()=>{ setGroup(g) }, 100)
                        }
                        break
                    case "servers":
                        if (data.servers && groupid){
                            const filteredServers = data.servers.filter((s)=>{ return groupID === s.groupid })
                            setTimeout(()=>{
                                setServers(filteredServers)
                                setLoading(false)
                            }, 150)
                        }
                        break
                    default:
                        console.log("Unsupported Message >> " + msg);
                }
            })
        }
    }, [ws, groupid, groupID])

    return useMemo(()=>{
        return (
            <div className="bg-dark" style={{minWidth: "100vw", minHeight: "100vh", display: "flex", flexDirection: "column"}}>
                <TopNav />
                <Container className="mt-2 mb-2" style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Card bg="secondary" text="white" style={{flex: 0, width: 500, maxWidth: "90%", margin: "auto"}}>
                        <Card.Body>
                            <Card.Title>{group?.name} Servers</Card.Title>
                            <Card.Text className="text-mute">Select a server to see the users</Card.Text>
                            {
                                loading?<Spinner />:<ListGroup>
                                {
                                    servers.map((s)=>{
                                        const url = "/history/" + s.groupid.toString() + "/" + s.id.toString()
                                        return <ListGroup.Item key={s.id} action variant="dark" href={url}>{s.name}</ListGroup.Item>
                                    })
                                }
                                </ListGroup>
                            }
                            { !loading && permission === Permission.SuperAdmin && <Button className="mt-4" variant="warning" as="a" href={"/history"}>Back to Group List</Button> }
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        )
    }, [loading, group, servers, permission])
}

export default SelectServerPage