import { createContext } from "react"
import { CumPnL, DailyPnL } from "../../../type/WSAPI"

export interface BasicDataArgs{
	loading: boolean
	username: string
	totalPnL: number
	totalTurnover: number

	cumPnLs: CumPnL[]
	dailyPnLs: DailyPnL[]

	MCBestCurve: number[]
	MCWorseCurve: number[]
	MCMidCurve: number[]
}

const DataContext = createContext<BasicDataArgs>({
	loading: true,
	username: "",
    totalPnL: 0,
	totalTurnover: 0,

	cumPnLs: [],
	dailyPnLs: [],

	MCBestCurve: [],
	MCWorseCurve: [],
	MCMidCurve: []
})

export default DataContext