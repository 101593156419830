import { useRef, useState, useMemo, useEffect } from "react"
import { WSHandle } from "../../function/WebSocketHandle"

import { SymbolDefinition, loadMetaData } from "../../function/MetaDataHandle"

import { Container, InputGroup } from 'react-bootstrap'
import Form from "react-bootstrap/Form"

import CallPutChart from './component/CallPutChart'
import UserAction from './component/UserActions'
import TopNav from "../../component/TopNav"
import { CallPutCount, HoldingData, TradInfo, WSResponse } from "../../type/WSAPI"
import { Permission } from "../../function/Enums"
import TradInfoPanel from "./component/TradInfoPanel"

function RealTimePage(){
    const [ws, setWS] = useState<WSHandle|null>(null)
    const connWS = useRef(false)

	const [warrantcbbcs, setWarrantCBBCs] = useState<{[key:string]:SymbolDefinition}>({})

	const [CallPutVolStatus, setCallPutVolStatus] = useState<{[key: string]: CallPutCount}>({})
	const [CallPutCountStatus, setCallPutCountStatus] = useState<{[key: string]: CallPutCount}>({})

	const [UserActions, setUserActions] = useState<WSResponse[]>([])
	const [TradInfos, setTradInfos]		= useState<TradInfo[]>([])
	const [UserHolding, setUserHolding] = useState<{[key: string]: HoldingData}>({})

	const TradInfosRef = useRef<TradInfo[]>([])

	const [filterU, setFilterU] = useState<string[]>([])
	const [minLights, setMinLights] = useState(5)

	useEffect(()=>{
		if (connWS.current === false && ws === null){
			connWS.current = true
			loadMetaData(setWarrantCBBCs, null);

			const websocket = new WSHandle()
            websocket.connect(setWS, (msg)=>{
                const data : WSResponse = JSON.parse(msg)
                switch (data.e) {
                    case "loginStatus":
                        if (!data.isLogon){
                            window.location.assign("/login")
                            break
                        }
                        if (data.permission as number < Permission.SuperAdmin){
                            let targetURL = "/history/"
                            if (data.group) targetURL += data.group.toString() + "/"
                            if (data.server) targetURL += data.server.toString() + "/"
                            if (data.permission as number < Permission.Admin) targetURL += data.username
                            window.location.assign(targetURL)
                            break
                        }

						websocket.send(JSON.stringify({e: "subRealtime"}))
						websocket.send(JSON.stringify({e: "subTradeMonitor"}))
                        break
					case "CallPutVolStatus":
						if (data.CallPut)
							setCallPutVolStatus(data.CallPut)
						break;
					case "CallPutCountStatus":
						if (data.CallPut)
							setCallPutCountStatus(data.CallPut)
						break;
					case "UserAction":
						setUserActions((last)=>{
							let newArr = last.slice()
							newArr.push(data)
							return newArr
						})
						break;
					case "UserHolding":
						if (data.userHolding)
							setUserHolding(data.userHolding)
						break
					case "HistoryTrades":
						if (!data.trad_infos_) break;
						TradInfosRef.current = data.trad_infos_.sort((a, b)=>{
							if (a.time_string_ > b.time_string_) return 1
							if (a.time_string_ < b.time_string_) return -1
							return 0
						})

						setTradInfos(TradInfosRef.current.slice())
						break;
					case "TradInfo":
						if (!data.trad_info_) break;
						TradInfosRef.current.push(data.trad_info_);
						setTradInfos(TradInfosRef.current.slice())
						break;
                    default:
                        console.log("Unsupported Message >> " + msg);
                }
            })
		}
	}, [ws])

	const CallPutSorting = useMemo(()=>{
		const countData = CallPutCountStatus;
		const volData = CallPutVolStatus;
		const keys = Object.keys(volData).filter((x)=>{
			if (volData[x].call + volData[x].put > 0 && (filterU.indexOf(x) > -1 || (minLights === 0 && filterU.length === 0) || (countData[x] && countData[x].call + countData[x].put >= minLights)))
				return true
			return false
		})

		return keys.sort((a, b)=>{ return -1 * ( (volData[a].call + volData[a].put) - (volData[b].call + volData[b].put) ) })
	}, [CallPutCountStatus, CallPutVolStatus, filterU, minLights])

	const CallPutHolding = useMemo(()=>{
		let holdingData : {[key: string]: CallPutCount} = {}

		if (Object.keys(warrantcbbcs).length === 0) return holdingData

		Object.keys(UserHolding).forEach((x)=>{
			Object.keys(UserHolding[x].holding).forEach((y)=>{
				if (warrantcbbcs[y]){
					const underlying = warrantcbbcs[y].underlying.toString()
					const type = warrantcbbcs[y].type

					if ((filterU.length === 0 && CallPutSorting.indexOf(underlying) !== -1) || filterU.indexOf(underlying) !== -1){
						if (!holdingData[underlying]) holdingData[underlying] = {call: 0, put: 0}

						if (type === "CALL" || type === "BULL"){
							holdingData[underlying].call += UserHolding[x].holding[y]
						}
						else if (type === "PUT" || type === "BEAR"){
							holdingData[underlying].put += UserHolding[x].holding[y]
						}
					}
				}
			})
		})

		return holdingData
	}, [UserHolding, warrantcbbcs, filterU, CallPutSorting])

	return useMemo(()=>{
		return (
			<div className="bg-light" style={{minWidth: "100vw", minHeight: "100vh", display: "flex", flexDirection: "column"}}>
				<TopNav />
				<Container fluid style={{flex: 1}}>
					<div className='mb-2' style={{display: "flex", gap: "0.5rem"}}>
						<InputGroup>
							<InputGroup.Text>Min Lights</InputGroup.Text>
							<Form.Control type="text" defaultValue={minLights} onBlur={(e)=>{ e.target.value = minLights.toString() }} onChange={(e)=>{
								const minLightsNumber = parseInt(e.target.value)
								if (!isNaN(minLightsNumber) && minLightsNumber.toString() === e.target.value){
									setMinLights(parseInt(e.target.value))
								}
							}}/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Underlying</InputGroup.Text>
							<Form.Control type="text" onChange={(e)=>{
								setFilterU(e.target.value.split(" ").filter(x => x !== ""))
							}}/>
						</InputGroup>
					</div>
					<div className="mb-2" style={{display: "flex", flexDirection: "row", gap: "0.5rem", width: "100%"}}>
						<CallPutChart name="Count" sort={CallPutSorting} data={CallPutCountStatus}/>
						<CallPutChart name="Volume" sort={CallPutSorting} data={CallPutVolStatus}/>
						<CallPutChart name="Holding" data={CallPutHolding}/>
					</div>
					<TradInfoPanel warrantcbbcs={warrantcbbcs} TradInfos={TradInfos}/>
					<UserAction filter={filterU} minLights={minLights} countData={CallPutCountStatus} UserActions={UserActions}/>
				</Container>
			</div>
		)
	}, [warrantcbbcs, minLights, filterU, CallPutSorting, CallPutCountStatus, CallPutVolStatus, CallPutHolding, UserActions, TradInfos])
}

export default RealTimePage