import { Decimal } from "decimal.js"
import { GetSpread } from './StockHandle'
import React, { Dispatch } from "react"

export interface SymbolDefinition {
	underlying: number
	issuer: string
	type: string
	expDate: number
	cratio: number
	imvol: number
	delta: number
}

function loadWarrants(setWarrant: Dispatch<React.SetStateAction<{[key: string]: SymbolDefinition}>>, setUnderlyings: Dispatch<React.SetStateAction<string[]>>|null){
	const currentTime = new Date()
	const dateString =  currentTime.getFullYear() + "-" + ("0"+(currentTime.getMonth() + 1)).slice(-2) + "-" + ("0"+currentTime.getDate()).slice(-2)
	const todayTime = new Date(dateString)
	fetch("/csv/warrant_search_results.csv").then(r=>r.text()).then((data)=>{
		let allSymbol : {[key: string]: SymbolDefinition} = {}

		data.split("\r\n").forEach((x, i)=>{
			if (i === 0)
				console.log(x.split(","))
			else if (x){
				const parts = x.split(",")

				let underlying = 0

				if (parts[3] === "HSI") underlying = 88888
				else if (parts[3] === "HSCE") underlying = 88889
				else underlying = parseInt(parts[3])

				if (!isNaN(underlying)){
					const tmpSymbolData : SymbolDefinition = {
						underlying: underlying,
						issuer: parts[0],
						type: parts[5].toUpperCase(),
						expDate: (new Date(parts[7]).getTime() - todayTime.getTime()) / (24*60*60*1000),
						cratio: parseFloat(parts[10]),
						imvol: parseFloat(parts[17]),
						delta: parseFloat(parts[18])
					}

					allSymbol[parseInt(parts[1])] = tmpSymbolData

					if (setUnderlyings){
						setUnderlyings((last)=>{
							if (last.indexOf(""+underlying) >= 0) return last;
							return [...last, ""+underlying]
						})
					}
				}
			}
		})

		setWarrant((last)=>{
			Object.keys(allSymbol).forEach((x)=>{
				last[x] = allSymbol[x]
			})
			return last;
		})
	})
}

function loadCBBCs(setCBBC: Dispatch<React.SetStateAction<{[key: string]: SymbolDefinition}>>, setUnderlyings: Dispatch<React.SetStateAction<string[]>>|null){
	const currentTime = new Date()
	const dateString =  currentTime.getFullYear() + "-" + ("0"+(currentTime.getMonth() + 1)).slice(-2) + "-" + ("0"+currentTime.getDate()).slice(-2)
	const todayTime = new Date(dateString)
	fetch("/csv/cbbc_search_results.csv").then(r=>r.text()).then((data)=>{
		let allSymbol : {[key: string]: SymbolDefinition} = {}

		data.split("\r\n").forEach((x, i)=>{
			if (i === 0)
				console.log(x.split(","))
			else if (x){
				const parts = x.split(",")

				let underlying = 0

				if (parts[3] === "HSI") underlying = 88888
				else if (parts[3] === "HSCE") underlying = 88889
				else underlying = parseInt(parts[3])

				if (!isNaN(underlying)){
					const tmpSymbolData = {
						underlying: underlying,
						issuer: parts[0],
						type: parts[5].toUpperCase(),
						expDate: (new Date(parts[14]).getTime() - todayTime.getTime()) / (24*60*60*1000),
						callValue: parseFloat(parts[6]),
						shares: new Decimal(parts[20]).mul(1000000).toNumber(),
						cratio: parseFloat(parts[9]),
						imvol: NaN,
						delta: NaN
					}

					let spread = GetSpread(tmpSymbolData.callValue);
					let modNum = new Decimal(tmpSymbolData.callValue).mod(spread).toNumber()
					if (modNum > 0){
						tmpSymbolData.callValue = tmpSymbolData.type === "BULL" ?
							new Decimal(tmpSymbolData.callValue).minus(modNum).toNumber()
							: new Decimal(tmpSymbolData.callValue).plus(modNum).toNumber()
					}

					allSymbol[parseInt(parts[1])] = tmpSymbolData

					if (setUnderlyings){
						setUnderlyings((last)=>{
							if (last.indexOf(""+underlying) >= 0) return last;
							return [...last, ""+underlying]
						})
					}
				}
			}
		})

		setCBBC((last)=>{
			Object.keys(allSymbol).forEach((x)=>{
				last[x] = allSymbol[x]
			})
			return last;
		})
	})
}

function loadMetaData(setWarrantCBBCs: Dispatch<React.SetStateAction<{[key: string]: SymbolDefinition}>>, setUnderlyings: Dispatch<React.SetStateAction<string[]>>|null=null){
	loadWarrants(setWarrantCBBCs, setUnderlyings)
	loadCBBCs(setWarrantCBBCs, null)
}


export default loadMetaData
export { loadMetaData, loadWarrants, loadCBBCs }