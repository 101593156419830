import { registerTheme } from "echarts"

function loadDark(){
    const contrastColor = '#B9B8CE';
    const colorPalette = [
        '#4992ff',
        '#7cffb2',
        '#fddd60',
        '#ff6e76',
        '#58d9f9',
        '#05c091',
        '#ff8a45',
        '#dd79ff'
    ]


    registerTheme('dark', {
        darkMode: true,

        color: colorPalette,
        axisPointer: {
            lineStyle: {
                color: '#fff'
            },
            crossStyle: {
                color: '#fff'
            },
            label: {
                color: '#fff'
            }
        },
        legend: {
            textStyle: {
                color: contrastColor
            }
        },
        textStyle: {
            color: contrastColor
        }
    });
}

export { loadDark }