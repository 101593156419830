import { useMemo, useContext } from "react";
import DataContext from "./Context";

import { Card, Spinner } from "react-bootstrap";
import ReactECharts from 'echarts-for-react';

function MonthlyPnL(){
	const {loading, dailyPnLs} = useContext(DataContext)

	const { Dates, PnLs } = useMemo(()=>{
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
		const pnls = months.map(()=>{ return 0 })

		dailyPnLs.forEach((x)=>{
			const month = new Date(x.epoch).getMonth()
			pnls[month] += x.pnl
		})

		return {
			Dates: months,
			PnLs: pnls
		}
	}, [dailyPnLs])

	return useMemo(()=>{
		if (loading){
			return (
				<Card bg="secondary" text="light" style={{height: "100%"}}>
					<Card.Header className="fs-5">Monthly P&L</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<Spinner style={{fontSize: 16}}/>
						</div>
					</Card.Body>
				</Card>
			)
		}

		if (dailyPnLs.length === 0){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">Monthly P&L</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<span>No Data</span>
						</div>
					</Card.Body>
				</Card>
			)
		}
		
		return (
			<Card bg="secondary" text="light" style={{height: "100%"}}>
				<Card.Header className="fs-5">Monthly P&L</Card.Header>
				<Card.Body>
					<ReactECharts theme="dark" style={{minHeight: 248, height: "100%"}} option={{
						grid: {
							top: "5%", left: "70px", right: "30px", bottom: "10%"
						},
						xAxis: {
							type: "category",
							data: Dates
						},
						yAxis: {
							type: "value"
						},
						tooltip: {},
						dataZoom: {
							type: "inside"
						},
						series: {
							name: "Monthly P&L",
							type: "bar",
							data: PnLs
						}
					}}/>
				</Card.Body>
			</Card>
		)
	}, [loading, dailyPnLs, Dates, PnLs])
}

export default MonthlyPnL