import { Decimal } from 'decimal.js'

function GetSpread(price: number){
    let spread = 0;

    if (price >= 0.01 && price < 0.25)
        spread = 0.001
    else if (price >= 0.25 && price  < 0.5)
        spread = 0.005
    else if (price >= 0.5 && price  < 10)
        spread = 0.01
    else if (price >= 10 && price  < 20)
        spread = 0.02
    else if (price >= 20 && price  < 100)
        spread = 0.05
    else if (price >= 100 && price  < 200)
        spread = 0.1
    else if (price >= 200 && price  < 500)
        spread = 0.2
    else if (price >= 500 && price  < 1000)
        spread = 0.5
    else
        spread = 1
    
    return spread
}

function ReturnNextUpPrice(price: number)
{
    let spread = new Decimal(0);
    if (0.01 <= price && price < 0.25)
        spread = new Decimal(0.001);
    else if (0.25 <= price && price < 0.50)
        spread = new Decimal(0.005);
    else if (0.50 <= price && price < 10.00)
        spread = new Decimal(0.01);
    else if (10.00 <= price && price < 20.00)
        spread = new Decimal(0.02);
    else if (20.00 <= price && price < 100.00)
        spread = new Decimal(0.05);
    else if (100.00 <= price && price < 200.00)
        spread = new Decimal(0.1);
    else if (200.00 <= price && price < 500.00)
        spread = new Decimal(0.2);
    else if (500.00 <= price && price < 1000.00)
        spread = new Decimal(0.5);
    return spread.add(price).toNumber();
}

function ReturnNextDownPrice(price: number)
{
    let spread = new Decimal(0);
    if (0.01 < price && price <= 0.25)
        spread = new Decimal(0.001);
    else if (0.25 < price && price <= 0.50)
        spread = new Decimal(0.005);
    else if (0.50 < price && price <= 10.00)
        spread = new Decimal(0.01);
    else if (10.00 < price && price <= 20.00)
        spread = new Decimal(0.02);
    else if (20.00 < price && price <= 100.00)
        spread = new Decimal(0.05);
    else if (100.00 <= price && price <= 200.00)
        spread = new Decimal(0.1);
    else if (200.00 < price && price <= 500.00)
        spread = new Decimal(0.2);
    else if (500.00 < price && price <= 1000.00)
        spread = new Decimal(0.5);
    return new Decimal(price).minus(spread).toNumber();
}

function ReturnNextNUpPrice(price: number, n: number){
    let newPrice = price;
    for (let i = 0; i < n; i++)
        newPrice = ReturnNextUpPrice(newPrice)
    return newPrice;
}

function ReturnNextNDownPrice(price: number, n: number){
    let newPrice = price;
    for (let i = 0; i < n; i++)
        newPrice = ReturnNextDownPrice(newPrice)
    return newPrice;
}

export { GetSpread, ReturnNextUpPrice, ReturnNextDownPrice, ReturnNextNUpPrice, ReturnNextNDownPrice }