import { useMemo, useContext } from "react";
import DateTimeProcessor from "../../../function/DateTimeProcessor";
import DataContext from "./Context";

import { Card, Spinner } from "react-bootstrap";
import ReactECharts from 'echarts-for-react';

function PnLCurve(){
	const {loading, dailyPnLs, MCBestCurve, MCMidCurve, MCWorseCurve} = useContext(DataContext)

	const { Dates, PnLs } = useMemo(()=>{
		const pnls: number[] = []

		dailyPnLs.forEach((x, i)=>{
			if (i === 0){
				pnls.push(x.pnl)
				return
			}

			pnls.push(x.pnl + pnls[i - 1])
		})

		return {
			Dates: dailyPnLs.map((x)=>{ return new Date(x.epoch) }),
			PnLs: pnls
		}
	}, [dailyPnLs])

	return useMemo(()=>{
		if (loading){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">Accumulate P&L Curve</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<Spinner style={{fontSize: 16}}/>
						</div>
					</Card.Body>
				</Card>
			)
		}
		if (PnLs.length === 0){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">Accumulate P&L Curve</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<span>No Data</span>
						</div>
					</Card.Body>
				</Card>
			)
		}

        const emptyPred = MCBestCurve.map(()=>"-")
        const emptyPnLs = PnLs.map(()=>"-")
        const latestPnL = PnLs[PnLs.length - 1]

        const DatesWithPred = [...Dates.map((x)=>{ return DateTimeProcessor.DateToString(x, DateTimeProcessor.DateStringFormat.yMd) }), ...MCBestCurve.map((x, i)=>{ return "Pred-Day" + (i+1).toString() })]
		
		return (
			<Card bg="secondary" text="white">
				<Card.Header className="fs-5">Accumulate P&L Curve</Card.Header>
				<Card.Body>
					<ReactECharts theme="dark" style={{height: 248}} option={{
						grid: {
							top: "5%", left: "70px", right: "30px", bottom: "10%"
						},
						xAxis: {
							type: "category",
							data: DatesWithPred
						},
						yAxis: {
							type: "value",
                            scale: true
						},
						tooltip: {},
						dataZoom: {
							type: "inside"
						},
						series: [
                            {
                                name: "P&L",
                                type: "line",
                                data: [...PnLs, ...emptyPred]
						    },
                            {
                                name: "Best Pred",
                                type: "line",
                                data: [...emptyPnLs, ...MCBestCurve.map((x)=>latestPnL + x)]
                            },
                            {
                                name: "Mid Pred",
                                type: "line",
                                data: [...emptyPnLs, ...MCMidCurve.map((x)=>{return latestPnL + x})],
                                itemStyle: {
                                    color: "#999999"
                                }
                            },
                            {
                                name: "Worse Pred",
                                type: "line",
                                data: [...emptyPnLs, ...MCWorseCurve.map((x)=>{return latestPnL + x})],
                                itemStyle: {
                                    color: "#ff6e76"
                                }
                            }
                        ]
					}}/>
				</Card.Body>
			</Card>
		)
	}, [loading, Dates, PnLs, MCBestCurve, MCWorseCurve, MCMidCurve])
}

export default PnLCurve