function parseNumberToString(number: number){
    return parseBigNumber(number)
}

function parseBigNumber(number: number){
    if (number > 1000000) return (Math.round(number / 10000) / 100) + "M"
    if (number > 1000) return (Math.round(number / 10) / 100) + "K"
    return number
}

export { parseNumberToString }