const Permission = {
    User: 0,
    Admin: 1,
    SuperAdmin: 2
}

const Side = {
    Bid: 1,
    Ask: 2
}

export {
    Permission, Side
}