import SHA1 from "crypto-js/sha1"
import { useEffect, useMemo, useRef, useState } from "react"
import { Button, Card, Form, FormControl, FormGroup, FormLabel } from "react-bootstrap"
import CookieHandle from "../function/CookieHandle"
import { Permission } from "../function/Enums"
import { WSHandle } from "../function/WebSocketHandle"
import { WSRequest, WSResponse } from "../type/WSAPI"

function LoginPage(){
    const [ws, setWS] = useState<WSHandle|null>(null)
    const [loginFailure, setLoginFailure] = useState(false)
    const connWS = useRef(false)

    useEffect(() => {
        if (connWS.current === false && ws === null){
            connWS.current = true

            const websocket = new WSHandle()
            websocket.connect(setWS, (msg)=>{
                const data : WSResponse = JSON.parse(msg)
                switch (data.e){
                    //@ts-expect-error
                    case "login":
                        if (!data.isLogon || !data.username || !data.loginUUID){
                            setLoginFailure(true)
                            break
                        }
                        CookieHandle.set("username", data.username)
                        CookieHandle.set("loginUUID", data.loginUUID)
                        // FALLTHROUGH
                    case "loginStatus":
                        if (!data.isLogon) break

                        let targetURL = "/history/"
                        if (data.permission as number === Permission.SuperAdmin) targetURL = "/portal"
                        if (data.group) targetURL += data.group.toString() + "/"
                        if (data.server) targetURL += data.server.toString() + "/"
                        if (typeof(data.permission) != "undefined" && data.permission < Permission.Admin) targetURL += data.username
                        window.location.assign(targetURL)
                        break
                    default:
                        console.log("Unsupported message >> " + msg);
                }
            })
        }
    }, [ws])

    return useMemo(()=>{
        return (
            <div style={{width: "100vw", height: "100vh", position: "relative"}}>
                <Card style={{width: 500, height: 300, maxWidth: "90%", top: "calc(100vh / 2 - 150px)", left: "calc(100vw / 2)", transform: "translate(-50%,0)"}}>
                    <Card.Header className="fs-5">Login</Card.Header>
                    <Card.Body>
                            <Form onSubmit={(e)=>{
                                e.preventDefault()
                                e.stopPropagation()
                                const formData = new FormData(e.target as HTMLFormElement)
                                const username = formData.get("username")?.toString()
                                const password = formData.get("password")?.toString()

                                if (username && password && username.length > 0 && password.length > 0){
                                    const request: WSRequest = {
                                        e: "login",
                                        username: username,
                                        password: SHA1(password).toString()
                                    }

                                    ws?.send(JSON.stringify(request))
                                    return 
                                }

                                setLoginFailure(true)
                            }}>
                                <FormGroup className="mb-2">
                                    <FormLabel>Username</FormLabel>
                                    <FormControl type="text" name="username" isInvalid={loginFailure}/>
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <FormLabel>Password</FormLabel>
                                    <FormControl type="password" name="password" isInvalid={loginFailure}/>
                                    <FormControl.Feedback type="invalid">Wrong Username or Password</FormControl.Feedback>
                                </FormGroup>
                                <Button type="submit">Login</Button>
                            </Form>
                    </Card.Body>
                </Card>
            </div>
        )
    }, [ws, loginFailure])
}

export default LoginPage