const DateStringFormat = {
    hm:     0,
    hms:    1,
    Md:     2,
    yM:     3,
    yMd:    4
}

function EpochToDate(num: number){
    if (num < 2147483648) return new Date(num * 1000)
    return new Date(num)
}

function DateToString(date?: Date, format:number = DateStringFormat.hm){
    const dateObj = date ?? new Date()

    const year      = dateObj.getFullYear()
    const month     = ("0" + (dateObj.getMonth()+1)).slice(-2)
    const day       = ("0" + dateObj.getDate()     ).slice(-2)
    const hour      = ("0" + dateObj.getHours()    ).slice(-2)
    const min       = ("0" + dateObj.getMinutes()  ).slice(-2)
    const second    = ("0" + dateObj.getSeconds()  ).slice(-2)

    if (format === DateStringFormat.hm)     return (hour + ":" + min)
    if (format === DateStringFormat.hms)    return (hour + ":" + min + ":" + second)
    if (format === DateStringFormat.Md)     return (month + "/" + day)
    if (format === DateStringFormat.yM)     return (year + "-" + month)
    if (format === DateStringFormat.yMd)    return (year + "-" + month + "-" + day)
    return ""
}

const DateTimeProcessor = {
    DateStringFormat, EpochToDate, DateToString 
}

export default DateTimeProcessor