import { useMemo, useEffect, useState, useRef } from "react";
import CookieHandle from "../../function/CookieHandle";

import TopNav from "../../component/TopNav";
import { Card, Container, ListGroup, Spinner } from "react-bootstrap";
import { WSHandle } from "../../function/WebSocketHandle";
import { GroupData, WSResponse } from "../../type/WSAPI";
import { Permission } from "../../function/Enums";

function SelectGroupPage(){
    const [username, loginUUID] = useMemo(()=>{ return [CookieHandle.get("username"), CookieHandle.get("loginUUID")] }, [])

    useEffect(()=>{
        if (!username || !loginUUID){
            window.location.assign("/login")
            return
        }
    }, [username, loginUUID])

    const [ws, setWS] = useState<WSHandle|null>(null)
    const connWS = useRef(false)

    const [loading, setLoading] = useState(true)
    const [groups, setGroups] = useState<GroupData[]>([])

    useEffect(()=>{
        // Before Start Conn
        if (connWS.current === false && ws === null){
            connWS.current = true

            const websocket = new WSHandle()
            websocket.connect(setWS, (msg)=>{
                const data : WSResponse = JSON.parse(msg)
                switch (data.e) {
                    case "loginStatus":
                        if (!data.isLogon){
                            window.location.assign("/login")
                            break
                        }
                        if (data.permission as number < Permission.SuperAdmin){
                            let targetURL = "/history/"
                            if (data.group) targetURL += data.group.toString() + "/"
                            if (data.server) targetURL += data.server.toString() + "/"
                            if (data.permission as number < Permission.Admin) targetURL += data.username
                            window.location.assign(targetURL)
                            break
                        }
                        websocket.send(JSON.stringify({e: "groups"}))
                        break
                    case "groups":
                        if (!data.groups) return 
                        setTimeout(()=>{
                            setGroups(data.groups as GroupData[])
                            setLoading(false)
                        }, 100)
                        break
                    default:
                        console.log("Unsupported Message >> " + msg);
                }
            })
        }
    }, [ws])

    return useMemo(()=>{
        if (loading) return null
        
        return (
            <div className="bg-dark" style={{minWidth: "100vw", minHeight: "100vh", display: "flex", flexDirection: "column"}}>
                <TopNav />
                <Container className="mt-2 mb-2" style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                    <Card bg="secondary" text="white" style={{flex: 0, width: 500, maxWidth: "90%", margin: "auto"}}>
                        <Card.Body>
                            <Card.Title>Server Groups</Card.Title>
                            <Card.Text className="text-mute">Select a group to see the servers</Card.Text>
                            {
                                loading? <Spinner /> :
                                <ListGroup>
                                    {
                                        groups.map((g)=>{
                                            return <ListGroup.Item key={g.id} action variant="dark" href={"/history/" + g.id.toString()}>{g.name}</ListGroup.Item>
                                        })
                                    }
                                </ListGroup>
                            }
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        )
    }, [loading, groups])
}

export default SelectGroupPage