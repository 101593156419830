import { useMemo, useContext } from "react"
import DataContext from "./Context"

import { Card, Spinner, Table } from "react-bootstrap"

function PnLByWeekAndTime(){
    const {loading, cumPnLs} = useContext(DataContext)

	const { PnLByWeekDay, PnLByTime } = useMemo(()=>{
        const pnlByWeekDay = {
            key: ["Mon", "Tue", "Wed", "Thu", "Fri"],
            amValue: [0, 0, 0, 0, 0],
            pmValue: [0, 0, 0, 0, 0],
        }
        const pnlByTime = {
            key: [9, 10, 11, 13, 14, 15],
            value: [0, 0, 0, 0, 0, 0]
        }

        cumPnLs.forEach((x)=>{
            const date = new Date(x.startEpoch)
            const weekday = date.getDay() - 1
            const hour = date.getHours()

            if (hour < 13)
                pnlByWeekDay.amValue[weekday] = pnlByWeekDay.amValue[weekday] + x.pnl
            else
                pnlByWeekDay.pmValue[weekday] = pnlByWeekDay.pmValue[weekday] + x.pnl
            
            const index = pnlByTime.key.indexOf(hour)
            if (index === -1) return
            pnlByTime.value[index] = pnlByTime.value[index] + x.pnl
        }, [])

        return {
            PnLByWeekDay: pnlByWeekDay,
            PnLByTime: pnlByTime
        }
	}, [cumPnLs])

	return useMemo(()=>{
		if (loading){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">PnL Distribution</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<Spinner style={{fontSize: 16}}/>
						</div>
					</Card.Body>
				</Card>
			)
		}

        if (cumPnLs.length === 0){
			return (
				<Card bg="secondary" text="light">
					<Card.Header className="fs-5">PnL By Weekdays / Time</Card.Header>
					<Card.Body>
						<div style={{margin: "100px auto", width: "fit-content"}}>
							<span>No Data</span>
						</div>
					</Card.Body>
				</Card>
			)
		}
		
		return (
			<Card bg="secondary" text="white">
				<Card.Header className="fs-5">PnL By Weekdays / Time</Card.Header>
				<Card.Body style={{height: "280px", overflow: "auto"}}>
                    <div style={{minWidth: 600}}>
                        <Table className="text-light" size="sm">
                            <thead>
                                <tr>{ ["PnL By Weekdays", ...PnLByWeekDay.key].map((x, i)=>{ return <th key={i} style={{textAlign: i===0?"left":"right"}}>{x}</th> }) }</tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        ["AM", ...PnLByWeekDay.amValue].map((x, i)=>{
                                            if (typeof(x) === "string") return <th key={i}>{x}</th>
                                            return <td key={i} style={{textAlign: "right"}} className={x>0?"text-green-200":"text-red-200"}>{(Math.round(x * 100) / 100).toLocaleString()}</td>
                                        })
                                    }
                                </tr>
                                <tr>
                                    {
                                        ["PM", ...PnLByWeekDay.pmValue].map((x, i)=>{
                                            if (typeof(x) === "string") return <th key={i}>{x}</th>
                                            return <td key={i} style={{textAlign: "right", width: (80/5).toFixed(2) + "%"}} className={x>0?"text-green-200":"text-red-200"}>{(Math.round(x * 100) / 100).toLocaleString()}</td>
                                        })
                                    }
                                </tr>
                            </tbody>
                        </Table>
                        <br/>
                        <Table className="text-light" size="sm">
                            <thead>
                                <tr>
                                    { ["Time", ...PnLByTime.key].map((x, i)=>{
                                        if (typeof(x) === "string") return <th key={i} style={{textAlign: "left"}}>{x}</th>
                                        return <th key={i} style={{textAlign: "right"}}>{("0" + x).slice(-2) + ":00"}</th>
                                    }) }
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        ["PnL", ...PnLByTime.value].map((x, i)=>{
                                            if (typeof(x) === "string") return <th key={i}>{x}</th>
                                            return <td key={i} style={{textAlign: "right", width: (85/6).toFixed(2) + "%"}} className={x>0?"text-green-200":"text-red-200"}>{(Math.round(x * 100) / 100).toLocaleString()}</td>
                                        })
                                    }
                                </tr>
                            </tbody>
                        </Table>
                    </div>
				</Card.Body>
			</Card>
		)
	}, [loading, cumPnLs, PnLByWeekDay, PnLByTime])
}

export default PnLByWeekAndTime